<template>
    <!-- 模板三 -->
    <div class="tpl_three">
        <div class="base-width">
            <!-- 面包屑 -->
            <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
            <!-- 店铺 -->
            <ShopBase :shop_info="shop_info" @followClick="followClick" @searchClick="searchClick"></ShopBase>
            <!-- 切换栏 -->
            <div class="tabs_box" v-ani.fade-up>
                <div :class="['tabs', tabsIndex == index ? 'active' : '']" v-for="(item, index) in tabsArr" :key="index"
                    @click="() => tabsIndex = index">
                    {{ item }}
                </div>
            </div>
            <!-- 首页 -->
            <template v-if="tabsIndex == 0">
                <Banner style="margin-top: 0.2rem;" class="img" :list="tplObj.top_banner" height="6rem" v-ani.fade-up />
                <div class="home_box">
                    <!-- 公司简介 -->
                    <div class="about_box" v-ani.fade-up>
                        <div class="center_img" style="margin-top: 0;">
                            <img class="img_one" src="../../../assets/images/tplImg/img11.png">
                        </div>
                        <div class="about_bottom">
                            <div class="bott_block">
                                <div class="b_num">{{ tplObj.company_schaft_time }}</div>
                            </div>
                            <div class="bott_block b_1">
                                <div class="b_num">{{ tplObj.registerkapital }}</div>
                            </div>
                            <div class="bott_block b_2">
                                <div class="b_num">{{ tplObj.unternehmen }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="house_box" v-ani.fade-up>
                        <div class="house_item">
                            <img class="house_img" :src="tplObj.company_profile_image">
                            {{ tplObj.company_profile }}
                        </div>
                    </div>
                    <!-- 源头好货 -->
                    <div class="center_img" style="margin-top: 0;">
                        <img class="img_one" src="../../../assets/images/tplImg/img12.png">
                    </div>
                    <div class="house_box" v-ani.fade-up>
                        <div class="house_item">
                            <img class="house_img" :src="tplObj.good_source_image">
                            {{ tplObj.good_source }}
                        </div>
                    </div>
                    <!-- 严选好物 -->
                    <div class="center_img">
                        <img class="img_one" src="../../../assets/images/tplImg/img13.png">
                    </div>
                    <div class="strict_box">
                        <div class="strict_content">
                            <div class="strict_list" v-for="(item, index) in tplObj.select_good" :key="index" v-ani.fade-up
                                @click="jumpClick(item.source_id)">
                                <img class="str_img" :src="item.image" />
                                <div class="str_name">{{ item.goods_name }}
                                </div>
                                <div class="str_price">
                                    <div class="price_line"><span>狂欢价</span> ￥{{ item.price }}</div>
                                    <img src="../../../assets/images/tplImg/img19.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 店铺热销 -->
                    <div class="center_img">
                        <img class="img_one" src="../../../assets/images/tplImg/img14.png">
                    </div>
                    <div class="hot_box" v-for="(item, index) in tplObj.hot_store" :key="item.jump_goods_id" v-ani.fade-up
                        @click="jumpClick(item.source_id)">
                        <div class="hot_list">
                            <div class="l">
                                <img class="l_img" :src="item.image" />
                            </div>
                            <div class="r">
                                <div class="r_name">{{ item.goods_name
                                }}法律手段会法律手段会计法发了数控刀具福利卡手机大发反馈拉屎经典复刻辣椒水代发福利卡拒收到付计法发了数控刀具福利卡手机大发反馈拉屎经典复刻辣椒水代发福利卡拒收到付</div>
                                <div class="r_price">
                                    成交价 <span>￥{{ item.price }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 供应项目 -->
                    <div class="center_img">
                        <img class="img_one" src="../../../assets/images/tplImg/img15.png">
                    </div>
                    <div class="supply_box" v-for="(item, index) in tplObj.supply_project" :key="index" v-ani.fade-up>
                        <div class="supply_list">
                            <div class="supp_title">{{ item.title }}</div>
                            <div class="supply_content">
                                <div class="l">

                                    <div class="supp_two">项目规模：<span>{{ item.project_scale || '暂无' }}</span>
                                    </div>
                                    <div class="supp_two">业主单位：<span>{{ item.owner_unit || '暂无' }}</span>
                                    </div>
                                    <div class="supp_two">
                                        总承包单位：<span>{{ item.total_unit || '暂无' }}</span></div>
                                    <div class="supp_two">
                                        项目投资规模：<span>{{ item.authentication_money || '暂无' }}</span></div>
                                    <div class="supp_two">项目位置：<span>{{ item.project_location || '暂无' }}</span>
                                    </div>
                                    <div class="supp_btn" @click="jumpClick(item.source_id)">
                                        <img src="../../../assets/images/tplImg/img23.png">
                                    </div>
                                </div>
                                <div class="r">
                                    <ImgRatio :src='item.original_img' :width="400" :height="300" class="img" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- 店铺资质 -->
                    <div class="center_img">
                        <img class="img_one" src="../../../assets/images/tplImg/img16.png">
                    </div>
                    <div class="zizhi_box">
                        <div class="zizhi_content">
                            <div class="zizhi_list" v-for="(item, index) in tplObj.certificate_image" :key="index"
                                v-ani.fade-up>
                                <viewer :images="tplObj.certificate_image">
                                    <img :src="item">
                                </viewer>
                            </div>
                        </div>
                    </div>
                    <div class="bo_img">
                        <img src="../../../assets/images/tplImg/img10.png">
                    </div>
                </div>
            </template>
            <!-- 商品 -->
            <div style="background-color: #ecf5fa;padding-top: 20px;margin-top: 20px;" v-if="tabsIndex == 1">
                <div class="base-width">
                    <!-- 筛选 -->
                    <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
                    <div class="md-prod-list">
                        <ProductItem v-for="item in goodsArr" :value='item' :key="item.goods_id" v-ani.fade-up />
                    </div>
                    <!-- 分页 -->
                    <div style="margin-top: 0.2rem;"></div>
                    <Pagination v-show="goodsTotal > 0 && tabsIndex == 1" :page="goodsData.p" :size="goodsData.page_num"
                        :total="goodsTotal" v-ani.fade-up @change="changePage" :key="pagKey" />
                    <div style="padding-bottom: 0.6rem;" v-if="tabsIndex == 1"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routes: { type: Array, default: () => [] },
        shop_info: { type: Object, default: () => { } },
        goodsArr: { type: Array, default: () => [] },
        goodsTotal: { type: Number, default: 0 },
        pagKey: { type: Number, default: 0 },
        goodsData: { type: Object, default: () => { } },
        tplObj: { type: Object, default: () => { } },

    },
    created() {

    },
    data() {
        return {
            tabsArr: ['首页', '商品'],
            tabsIndex: 0,
        }
    },
    methods: {
        jumpClick(url) {
            window.open(url, '_blank');
        },
        followClick() {
            this.$emit('followFn');
        },
        searchClick(keywords) {
            this.$emit('searchFn', keywords);
            this.tabsIndex = 1;
        },
        filterFn(obj) {
            this.$emit('filterFn', obj)
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs_box {
    margin-top: 0.2rem;
    display: flex;

    .tabs {
        margin-right: 0.2rem;
        width: 0.7rem;
        height: 0.4rem;
        line-height: 0.4rem;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-size: 0.2rem;
        cursor: pointer;

        &.active {
            background: linear-gradient(90deg, #6FB1FF 0%, #0261D5 100%);
            color: #fff;

        }
    }
}

.md-prod-list {
    margin-top: 0.2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.1rem;
}

.center_img {
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .img_one {
        width: 3.08rem;
        height: 0.74rem;
        z-index: 10;
    }
}

.home_box {
    width: 100%;
    background-image: url('../../../assets/images/tplImg/img6.png');
    background-repeat: repeat-y;
    background-position: top left;
}

.about_box {
    padding-top: 1rem;
    width: 100%;
    height: 4.25rem;
    box-sizing: border-box;
    background-image: url('../../../assets/images/tplImg/img5.png');
    background-size: 100%;

    .about_bottom {
        margin-top: 0.32rem;
        display: flex;
        justify-content: center;

        .bott_block {
            width: 1.66rem;
            height: 1.65rem;
            background-image: url('../../../assets/images/tplImg/img7.png');
            background-repeat: no-repeat;
            background-size: 100%;
            z-index: 10;

            &.b_1 {
                background-image: url('../../../assets/images/tplImg/img8.png');

            }

            &.b_2 {
                background-image: url('../../../assets/images/tplImg/img9.png');

            }

            .b_num {
                margin-top: 0.42rem;
                font-weight: bold;
                font-size: 0.32rem;
                color: #F7D799;
                text-align: center;
            }
        }
    }
}

.house_box {
    margin-top: 0.32rem;
    display: flex;
    justify-content: center;

    .house_item {
        padding: 0.48rem 0.78rem;
        box-sizing: border-box;
        width: 5.94rem;
        height: 6.56rem;
        background-image: url('../../../assets/images/tplImg/img17.png');
        background-repeat: no-repeat;
        background-size: cover;
        font-size: 0.18rem;
        color: #333333;

        .house_img {
            margin-bottom: 0.1rem;
            width: 4.4rem;
            height: 2.48rem;
        }
    }
}

.strict_box {
    margin-top: 0.32rem;
    display: flex;
    justify-content: center;

    .strict_content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.2rem;

        .strict_list {
            width: 2.9rem;
            height: 4rem;
            padding: 0.38rem 0.24rem;
            box-sizing: border-box;
            background-image: url('../../../assets/images/tplImg/img18.png');
            background-repeat: no-repeat;
            background-size: 100%;

            .str_img {
                width: 100%;
                display: block;
                height: 1.84rem;
                border-radius: 0.1rem 0.1rem 0 0;
            }

            .str_name {
                width: 100%;
                height: 0.88rem;
                font-size: 0.18rem;
                color: #FFFFFF;
                font-weight: 700;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            .str_price {
                display: flex;

                .price_line {
                    padding: 0 0.1rem;
                    width: fit-content;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    background: #AC2E2A;
                    font-size: 0.32rem;
                    color: #FCE8B5;

                    span {
                        font-size: 0.18rem;
                    }
                }


                img {
                    width: 0.2rem;
                    height: 0.4rem;
                }
            }
        }
    }
}

.hot_box {
    display: flex;
    justify-content: center;

    .hot_list {
        margin-top: 0.2rem;
        padding: 0.38rem 0.4rem;
        width: 5.94rem;
        height: 2.87rem;
        background-image: url('../../../assets/images/tplImg/img20.png');
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;

        .l {
            width: 2.1rem;
            height: 2rem;
            border-radius: 0.1rem;
            overflow: hidden;


            .l_img {
                width: 100%;
                height: 100%;
            }
        }

        .r {
            flex: 1;
            margin-left: 0.1rem;

            .r_name {
                height: 1.3rem;
                font-weight: bold;
                font-size: 0.2rem;
                color: #fff;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                overflow: hidden;
            }

            .r_price {
                margin-top: 0.1rem;
                padding: 0 0.2rem;
                width: 2.3rem;
                height: 0.6rem;
                line-height: 0.6rem;
                color: #FCE8B5;
                font-size: 0.18rem;
                font-weight: 700;
                background-image: url('../../../assets/images/tplImg/img21.png');
                background-repeat: no-repeat;
                background-size: 100%;

                span {
                    font-size: 0.28rem;
                }
            }

        }
    }
}

.supply_box {
    display: flex;
    justify-content: center;

    .supply_list {
        margin-top: 0.2rem;
        padding: 0.4rem;
        box-sizing: border-box;
        width: 9rem;
        height: 4.24rem;
        background-image: url('../../../assets/images/tplImg/img22.png');
        background-repeat: no-repeat;
        background-size: 100%;

        .supp_title {
            font-weight: bold;
            font-size: 0.3rem;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: #fff;
        }

        .supply_content {
            display: flex;
            justify-content: space-between;

            .l {
                flex: 1;

                .supp_two {
                    margin-top: 0.15rem;
                    font-size: 0.2rem;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    color: #FCE8B5;


                    span {
                        color: #fff;
                    }
                }

                .supp_btn {
                    margin-top: 0.5rem;

                    img {
                        width: 1.4rem;
                        height: 0.4rem;
                    }
                }
            }

            .r {
                margin-left: 0.3rem;
                width: 4rem;
                height: 3rem;
            }
        }


    }
}

.zizhi_box {
    margin-top: 0.32rem;
    display: flex;
    justify-content: center;

    .zizhi_content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.1rem;

        .zizhi_list {
            width: 2.93rem;
            height: 2.93rem;
            background-image: url('../../../assets/images/tplImg/img24.png');
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }
}

.bo_img {
    width: 100%;
    height: 7rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>