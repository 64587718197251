import { render, staticRenderFns } from "./Seller.vue?vue&type=template&id=cdfdb604&scoped=true"
import script from "./Seller.vue?vue&type=script&lang=js"
export * from "./Seller.vue?vue&type=script&lang=js"
import style0 from "./Seller.vue?vue&type=style&index=0&id=cdfdb604&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdfdb604",
  null
  
)

export default component.exports