<template>
    <!-- 模板五 -->
    <div class="tpl_five">
        <div class="base-width">
            <!-- 面包屑 -->
            <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
            <!-- 店铺 -->
            <ShopBase :shop_info="shop_info" @followClick="followClick" @searchClick="searchClick"></ShopBase>
            <!-- 切换栏 -->
            <div class="tabs_box" v-ani.fade-up>
                <div :class="['tabs', tabsIndex == index ? 'active' : '']" v-for="(item, index) in tabsArr" :key="index"
                    @click="() => tabsIndex = index">
                    {{ item }}
                </div>
            </div>
            <!-- 首页 -->
            <template v-if="tabsIndex == 0">
                <Banner style="margin-top: 0.2rem;" class="img" :list="tplObj.top_banner" height="6rem" v-ani.fade-up />
                <div class="home_box">
                    <!-- 公司简介 -->
                    <div class="about_block" v-ani.fade-up>
                        <div class="center_img" style="margin-top: 0;">
                            <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive2.png">
                        </div>
                        <div class="about_bottom">
                            <div class="bott_blocks">
                                <div class="bott_block">
                                    <div class="about_content">
                                        <div class="b_num">{{tplObj.company_schaft_time}}</div>
                                        <div class="b_bottom">
                                            <img class="b_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive3.png"></img>
                                            <div class="b_text">公司成立</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bott_block">
                                    <div class="about_content">
                                        <div class="b_num">{{tplObj.registerkapital}}</div>
                                        <div class="b_bottom">
                                            <img class="b_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive4.png"></img>
                                            <div class="b_text">注册资金</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bott_block">
                                    <div class="about_content">
                                        <div class="b_num">{{tplObj.unternehmen}}</div>
                                        <div class="b_bottom">
                                            <img class="b_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive5.png"></img>
                                            <div class="b_text">公司规模</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="about_box">
                                <img :src="tplObj.company_profile_image" />
                                <div class="about_text">{{tplObj.company_profile}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 源头好货 -->
                    <div class="center_img" style="margin-top: 0;">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive6.png">
                    </div>
                    <div class="house_box" v-ani.fade-up>
                        <div class="about_bottom" v-ani.fade-up>
                            <div class="about_box">
                                <img :src="tplObj.good_source_image">
                                {{ tplObj.good_source }}
                            </div>
                        </div>
                    </div>
                   
                    <!-- 严选好物 -->
                    <div class="center_img">
                        <img class="img_two" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive7.png">
                    </div>
                    <div class="strict_box">
                        <div class="strict_content">
                            <div class="strict_list" v-for="(item, index) in tplObj.select_good" :key="index" v-ani.fade-up
                                @click="jumpClick(item.source_id)">
                                <img class="str_img" :src="item.image" />
                                <div class="str_name">{{ item.goods_name }}</div>
                                <div class="str_price">
                                    ￥{{ item.price }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 店铺热销 -->
                    <div class="center_img">
                        <img class="img_two" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive9.png">
                    </div>
                    <div class="hot_box">
                        <div class="hot_content">
                            <div class="hot_list" v-for="(item) in tplObj.hot_store" :key="item.jump_goods_id" v-ani.fade-up
                             @click="jumpClick(item.source_id)">
                                <div class="hot_item">
                                    <div class="l">
                                        <img class="l_img" :src="item.image" />
                                    </div>
                                    <div class="r">
                                        <div class="r_name">{{ item.goods_name
                                        }}</div>
                                        <div class="r_price">
                                            <div class="r_price_text">热销价:</div>{{ item.price }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 供应项目 -->
                    <div class="center_img" v-if="tplObj.supply_project && tplObj.supply_project.length > 0">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive11.png">
                    </div>
                    <div class="engineer_box" v-if="tplObj.supply_project && tplObj.supply_project.length > 0">
                        <div class="engineer_list" v-for="(item,index) in tplObj.supply_project" :key="index">
                            <div class="en_top">
                                <div class="engineer_title overflow">
                                    {{item.title}}
                                </div>
                                <div class="engineer_tip" v-if="item.category_name">
                                    {{ item.category_name }}
                                </div>
                            </div>
                            <div class="engi_info">
                                <div class="engi_info_top">
                                    <div class="l">
                                        <img :src="item.original_img" /> 
                                    </div>
                                    <div class="r">
                                        <div class="l_block">
                                            <div class="block_describe">业主单位：</div>
                                            <div class="block_txt overflow">{{item.ownerUnit || '暂无'}}</div>
                                        </div>
                                        <div class="l_block">
                                            <div class="block_describe">总包单位：</div>
                                            <div class="block_txt overflow">{{item.totalUnit || '暂无'}}</div>
                                        </div>
                                        <div class="l_block">
                                            <div class="block_describe">项目投资规模：</div>
                                            <div class="block_txt overflow">
                                                ￥{{item.authentication_money || '暂无'}}
                                            </div>
                                        </div>
                                        <div class="l_block">
                                            <div class="block_describe">项目位置：</div>
                                            <div class="block_txt overflow">{{item.project_location || '暂无'}}</div>
                                        </div>            
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <!-- 店铺资质 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive12.png">
                    </div>
                    <div class="zizhi_box">
                        <div class="zizhi_content">
                            <div class="zizhi_list" v-for="(item, index) in tplObj.certificate_image" :key="index"
                                v-ani.fade-up>
                                <viewer :images="tplObj.certificate_image">
                                    <img :src="item">
                                </viewer>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </template>
            <!-- 商品 -->
            <div style="background-color: #ecf5fa;padding-top: 20px;margin-top: 20px;" v-if="tabsIndex == 1">
                <div class="base-width">
                    <!-- 筛选 -->
                    <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
                    <div class="md-prod-list">
                        <ProductItem v-for="item in goodsArr" :value='item' :key="item.goods_id" v-ani.fade-up />
                    </div>
                    <!-- 分页 -->
                    <div style="margin-top: 0.2rem;"></div>
                    <Pagination v-show="goodsTotal > 0 && tabsIndex == 1" :page="goodsData.p" :size="goodsData.page_num"
                        :total="goodsTotal" v-ani.fade-up @change="changePage" :key="pagKey" />
                    <div style="padding-bottom: 0.6rem;" v-if="tabsIndex == 1"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routes: { type: Array, default: () => [] },
        shop_info: { type: Object, default: () => { } },
        goodsArr: { type: Array, default: () => [] },
        goodsTotal: { type: Number, default: 0 },
        pagKey: { type: Number, default: 0 },
        goodsData: { type: Object, default: () => { } },
        tplObj: { type: Object, default: () => { } },

    },
    created() {

    },
    data() {
        return {
            tabsArr: ['首页', '商品'],
            tabsIndex: 0,
        }
    },
    methods: {
        jumpClick(url) {
            window.open(url, '_blank');
        },
        followClick() {
            this.$emit('followFn');
        },
        searchClick(keywords) {
            this.$emit('searchFn', keywords);
            this.tabsIndex = 1;
        },
        filterFn(obj) {
            this.$emit('filterFn', obj)
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs_box {
    margin-top: 0.2rem;
    display: flex;

    .tabs {
        margin-right: 0.2rem;
        width: 0.7rem;
        height: 0.4rem;
        line-height: 0.4rem;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-size: 0.2rem;
        cursor: pointer;

        &.active {
            background: linear-gradient(90deg, #6FB1FF 0%, #0261D5 100%);
            color: #fff;

        }
    }
}

.md-prod-list {
    margin-top: 0.2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.1rem;
}

.center_img {
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .img_one {
        width: 6.26rem;
        height: 1.08rem;
        z-index: 10;
    }
    .img_two{
        width: 4.94rem;
        height: 1.17rem;
        z-index: 10;
    }
}

.home_box {
    width: 100%;
    background: linear-gradient( 180deg, #8393E9 0%, #B9CFFA 49%, #8393E9 100%);
	background-repeat: repeat-y;
    position: relative;
}
.about_bottom{
    width: 8.2rem;
    padding: 0.32rem;
    background: #D9E5FF;
    .about_box {
        padding: 0.16rem 0.13rem;
        box-sizing: border-box;
        font-size: 0.18rem;
        color: #1A1A1A;
        line-height: 0.27rem;
        background: #FFFFFF;
        border-radius: 0.2rem;
        min-height: 2rem;
        img {
            float: right;
            margin-right: 0.1rem;
            width: 2.12rem;
            max-height: 2rem;
            border-radius: 0.2rem;
        }
    }
}

.about_block{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about_bottom{
        .bott_blocks {
            margin-top: 0.14rem;
            margin-bottom: 0.16rem;
            display: flex;
            justify-content: space-around;
            .bott_block {
                height: 1.61rem;
                background: linear-gradient(#DBFFFF 0%, #8CBAFC 22%, #4257EE 100%);
                box-shadow: 0px 0.09rem 0.05rem 0.01rem rgba(107,164,213,0.75);
                border-radius: 0.25rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                padding: 0.16rem;

                .about_content{
                    padding: 0 0.16rem;
                    height: 1.29rem;
                    background: #FFFFFF;
                    border-radius: 0.1rem;
                    .b_num {
                        font-weight: bold;
                        font-size: 0.32rem;
                        color: #4A63F0;
                        text-align: center;
                        margin-top: 0.2rem;
                        font-family: 'DINPRO-BOLD';
                    }
                    .b_bottom{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top:0.16rem;
                        .b_icon{
                            width: 0.24rem;
                            height: 0.24rem;
                        }
                        .b_text{
                            font-size: 0.28rem;
                            color: #4A63F0;
                            margin-left: 0.05rem;
                        }
                    }
                }
            }
        }
    }
}

.house_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.strict_box {
    margin-top: 0.32rem;
    display: flex;
    justify-content: center;

    .strict_content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.2rem;
        width: 8.21rem;
        background: #D9E5FF;
        padding: 0.32rem;
        box-sizing: border-box;

        .strict_list {
            width: 2.47rem;
            height: 3.47rem;
            box-sizing: border-box;
            background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive8.png');
            background-repeat: no-repeat;
            background-size: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.16rem;
            position: relative;

            .str_img {
                width: 2.1rem;
                height: 2.1rem;
                background: #FFFFFF;
                border-radius: 0.21rem;
            }

            .str_name {
                width: 100%;
                font-size: 0.18rem;
                color: #FFFFFF;
                font-weight: 700;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                padding-top: 0.16rem;
            }

            .str_price {
                width: 100%;
                font-size: 0.32rem;
                color: #FFFFFF;
                font-weight: 700;
                position: absolute;
                bottom: 0.15rem;
                left: 0.15rem;
            }
        }
    }
}

.hot_box {
    display: flex;
    justify-content: center;
    padding-bottom: 0.32rem;
    margin-top: 0.32rem;
    .hot_content{
        width: 8.21rem;
        background: #D9E5FF;
        padding: 0 0.32rem 0.32rem;
        box-sizing: border-box;
        .hot_list {
            padding: 0.56rem 0.24rem;
            width: 7.57rem;
            height: 3.58rem;
            background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplFive10.png');
            background-repeat: no-repeat;
            background-size: 100%;
            margin-top: 0.32rem;
            .hot_item{
                display: flex;
                .l {
                    width: 3.28rem;
                    height: 2.46rem;
                    background: #FFFFFF;
                    border-radius: 0.2rem;
                    overflow: hidden;
                    .l_img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 0.1rem;

                    .r_name {
                        line-height: 0.48rem;
                        font-weight: bold;
                        font-size: 0.32rem;
                        color: #fff;
                    }

                    .r_price {
                        font-weight: bold;
                        font-size: 0.51rem;
                        color: #FFFFFF;
                        display: flex;
                        align-items: flex-end;
                        .r_price_text{
                            font-weight: 400;
                            font-size: 0.24rem;
                            margin-right: 0.1rem;
                            margin-bottom: 0.08rem;
                        }
                    }

                }
            }
        }
    }
}
.engineer_box{
    display: flex;
    justify-content: center;
    .engineer_list {
        width: 8.21rem;
        background: #D9E5FF;
        padding: 0.3rem 0.16rem;
        margin-top: 0.24rem;
        .en_top{
            display: flex;
            margin-bottom: 0.22rem;
            align-items: center;
            .engineer_title {
                font-weight: 700;
                font-size: 0.28rem;
                color: #333333;
            }
            .engineer_tip{
                padding: 0.07rem 0.1rem;
                font-size: 0.24rem;
                color: #3486F4;
                line-height: 0.36rem;
                background: #E5ECFC;
                margin-left: 0.18rem;
                border-radius: 0.08rem;
            }
        }
        
        .engi_info {
            .l_block:nth-child(1),.l_block:nth-child(2),.l_block:nth-child(3){
                margin-bottom: 0.24rem;
            }
            .l_block {
                font-size: 0.24rem;
                
                display: flex;
                .block_describe {
                    color: #666;
                }
                .block_txt {
                    flex:1 ;
                }
            }
            .engi_info_top{
                display: flex;
                .r {
                    flex: 1;
                }
                .l {
                    width: 2.16rem;
                    height: 2.16rem; 
                    margin-right: 0.16rem;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}


.zizhi_box {
    margin-top: 0.32rem;
    padding-bottom: 0.32rem;
    display: flex;
    justify-content: center;
    .zizhi_content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.1rem;

        .zizhi_list {
            width: 3.95rem;
            height: 3.95rem;
            background: linear-gradient( 180deg, #8BB9FC 0%, #445AEE 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.3rem;

            img {
                width: 3.39rem;
                height: 3.39rem;
                background: #FFFFFF;
                border-radius: 0.16rem
            }
        }
    }
}

</style>