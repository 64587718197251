<template>
    <!-- 模板六 -->
    <div class="tpl_six">
        <div class="base-width">
            <!-- 面包屑 -->
            <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
            <!-- 店铺 -->
            <ShopBase :shop_info="shop_info" @followClick="followClick" @searchClick="searchClick"></ShopBase>
            <!-- 切换栏 -->
            <div class="tabs_box" v-ani.fade-up>
                <div :class="['tabs', tabsIndex == index ? 'active' : '']" v-for="(item, index) in tabsArr" :key="index"
                    @click="() => tabsIndex = index">
                    {{ item }}
                </div>
            </div>
            <!-- 首页 -->
            <template v-if="tabsIndex == 0">
                <Banner style="margin-top: 0.2rem;" class="img" :list="tplObj.top_banner" height="6rem" v-ani.fade-up />
                <div class="home_box">
                    <!-- 公司简介 -->
                    <div class="about_block" v-ani.fade-up>
                        <div class="center_img" style="margin-top: 0;">
                            <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix2.png">
                        </div>
                        <div class="about_bottom">
                            <div class="bott_block">
                                <div class="about_content">
                                    <div class="b_bottom">
                                        <img class="b_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix3.png" />
                                    </div>
                                    <div class="b_num">{{tplObj.company_schaft_time}}</div>
                                </div>
                            </div>
                            <div class="bott_block">
                                <div class="about_content">
                                    <div class="b_bottom">
                                        <img class="b_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix4.png" />
                                    </div>
                                    <div class="b_num">{{tplObj.registerkapital}}</div>
                                </div>
                            </div>
                            <div class="bott_block">
                                <div class="about_content">
                                    <div class="b_bottom">
                                        <img class="b_icon" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix5.png" />
                                    </div>
                                    <div class="b_num">{{tplObj.unternehmen}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="about_box">
                            <div class="about_box_content">
                                <img :src="tplObj.company_profile_image" />
                                <div class="about_text">{{tplObj.company_profile}}</div>
                            </div>
                        </div>
                        <div class="about_bottom_bg"></div>
                    </div>
                    <!-- 源头好货 -->
                    <div class="house_block">
                        <div class="center_img" style="margin-top: 0;">
                            <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix6.png">
                        </div>
                        <div class="house_top"></div>
                        <div class="house_box">
                            <div class="about_box_content">
                                <img :src="tplObj.good_source_image" />
                                {{ tplObj.good_source }}
                            </div>
                        </div>
                        <div class="about_bottom_bg">
                        </div>
                    </div>
                   
                    <!-- 严选好物 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix7.png">
                    </div>
                    <div class="strict_box">
                        <div class="house_top"></div>
                        <div class="strict_content">
                            <div class="strict_list" v-for="(item, index) in tplObj.select_good" :key="index" v-ani.fade-up
                                @click="jumpClick(item.source_id)">
                                <img class="str_img" :src="item.image" />
                                <!-- <div class="str_name">{{ item.goods_name }}</div> -->
                                <div class="str_price">
                                    ￥{{ item.price }}
                                </div>
                            </div>
                        </div>
                        <div class="about_bottom_bg"></div>
                    </div>
                    <!-- 店铺热销 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix7.png">
                    </div>
                    <div class="hot_box">
                        <div class="hot_content">
                            <div class="hot_list" v-for="(item) in tplObj.hot_store" :key="item.jump_goods_id" v-ani.fade-up
                             @click="jumpClick(item.source_id)">
                                <div class="hot_item">
                                    <div class="l">
                                        <img class="l_img" :src="item.image" />
                                    </div>
                                    <div class="r">
                                        <div class="r_name">{{ item.goods_name
                                        }}</div>
                                        <div class="r_price">￥{{ item.price }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 供应项目 -->
                    <div class="center_img" v-if="tplObj.supply_project && tplObj.supply_project.length > 0">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix11.png">
                    </div>
                    <div class="engineer_box" v-if="tplObj.supply_project && tplObj.supply_project.length > 0">
                        <div class="engineer_list" v-for="(item,index) in tplObj.supply_project" :key="index">
                            <div class="en_top">
                                <div class="engineer_title overflow">
                                    {{item.title}}
                                </div>
                                <div class="engineer_tip" v-if="item.category_name">
                                    {{ item.category_name }}
                                </div>
                            </div>
                            <div class="engi_info">
                                <div class="engi_info_top">
                                    <div class="l">
                                        <img :src="item.original_img" /> 
                                    </div>
                                    <div class="r">
                                        <div class="l_block">
                                            <div class="block_describe">业主单位：</div>
                                            <div class="block_txt overflow">{{item.ownerUnit || '暂无'}}</div>
                                        </div>
                                        <div class="l_block">
                                            <div class="block_describe">总包单位：</div>
                                            <div class="block_txt overflow">{{item.totalUnit || '暂无'}}</div>
                                        </div>
                                        <div class="l_block">
                                            <div class="block_describe">项目投资规模：</div>
                                            <div class="block_txt overflow">
                                                ￥{{item.authentication_money || '暂无'}}
                                            </div>
                                        </div>
                                        <div class="l_block">
                                            <div class="block_describe">项目位置：</div>
                                            <div class="block_txt overflow">{{item.project_location || '暂无'}}</div>
                                        </div>            
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <!-- 店铺资质 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix12.png">
                    </div>
                    <div class="zizhi_box">
                        <div class="house_top"></div>
                        <div class="zizhi_content">
                            <div class="zizhi_list" v-for="(item, index) in tplObj.certificate_image" :key="index"
                                v-ani.fade-up>
                                <viewer :images="tplObj.certificate_image">
                                    <img :src="item">
                                </viewer>
                            </div>
                        </div>
                        <div class="about_bottom_bg"></div>
                    </div>
                </div>
            </template>
            <!-- 商品 -->
            <div style="background-color: #ecf5fa;padding-top: 20px;margin-top: 20px;" v-if="tabsIndex == 1">
                <div class="base-width">
                    <!-- 筛选 -->
                    <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
                    <div class="md-prod-list">
                        <ProductItem v-for="item in goodsArr" :value='item' :key="item.goods_id" v-ani.fade-up />
                    </div>
                    <!-- 分页 -->
                    <div style="margin-top: 0.2rem;"></div>
                    <Pagination v-show="goodsTotal > 0 && tabsIndex == 1" :page="goodsData.p" :size="goodsData.page_num"
                        :total="goodsTotal" v-ani.fade-up @change="changePage" :key="pagKey" />
                    <div style="padding-bottom: 0.6rem;" v-if="tabsIndex == 1"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routes: { type: Array, default: () => [] },
        shop_info: { type: Object, default: () => { } },
        goodsArr: { type: Array, default: () => [] },
        goodsTotal: { type: Number, default: 0 },
        pagKey: { type: Number, default: 0 },
        goodsData: { type: Object, default: () => { } },
        tplObj: { type: Object, default: () => { } },

    },
    created() {

    },
    data() {
        return {
            tabsArr: ['首页', '商品'],
            tabsIndex: 0,
        }
    },
    methods: {
        jumpClick(url) {
            window.open(url, '_blank');
        },
        followClick() {
            this.$emit('followFn');
        },
        searchClick(keywords) {
            this.$emit('searchFn', keywords);
            this.tabsIndex = 1;
        },
        filterFn(obj) {
            this.$emit('filterFn', obj)
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs_box {
    margin-top: 0.2rem;
    display: flex;

    .tabs {
        margin-right: 0.2rem;
        width: 0.7rem;
        height: 0.4rem;
        line-height: 0.4rem;
        border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
        text-align: center;
        font-size: 0.2rem;
        cursor: pointer;

        &.active {
            background: linear-gradient(90deg, #6FB1FF 0%, #0261D5 100%);
            color: #fff;

        }
    }
}

.md-prod-list {
    margin-top: 0.2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.1rem;
}

.center_img {
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .img_one {
        width: 6.86rem;
        height: 1.11rem;
        z-index: 10;
    }
    .img_two{
        width: 4.94rem;
        height: 1.17rem;
        z-index: 10;
    }
}

.home_box {
    width: 100%;
    background: #0D131A;
	background-repeat: repeat-y;
    position: relative;
}
.about_bottom_bg{
    background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix16.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
    width: 8.2rem;
    height: 0.6rem;
}
.house_top{
    background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix17.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
    height: 1rem;
    width: 8.2rem;
}

.about_block{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .about_bottom {
        display: flex;
        justify-content: space-around;
        background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix14.png');
		background-repeat: no-repeat;
		background-size: 100%;
        position: absolute;
        top: 0.8rem;
        width: 8.2rem;
        .bott_block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            padding: 0.16rem;
            margin-top: 0.8rem;
            .about_content{
                .b_num {
                    font-weight: bold;
                    font-size: 0.32rem;
                    color: #FFFFFF;
                    text-align: center;
                    margin-top: 0.4rem;
                    font-family: 'DINPRO-BOLD';
                }
                .b_bottom{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top:0.16rem;
                    .b_icon{
                        width: 1.72rem;
                        height: 0.44rem;
                    }
                    .b_text{
                        font-size: 0.28rem;
                        color: #FFFFFF;
                        margin-left: 0.05rem;
                    }
                }
            }
        }
    }
    .about_box {
        background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix15.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
        background-position: top center;
        padding: 0.4rem 0.2rem 0rem 0.14rem;
        width: 8.2rem;
        .about_box_content{
            min-height: 3.2rem;
            padding: 0.16rem 0.13rem;
            margin-top: 1.6rem;
            font-size: 0.28rem;
            line-height: 0.42rem;
            background: #FFFFFF;
            border-radius: 0.2rem;
            color: #333333;
            img {
                float: right;
                margin-right: 0.1rem;
                width: 2.12rem;
                height: 2.83rem;
                border-radius: 0.2rem;
            }
        }
    }
}

.house_block{
    position: relative;
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .center_img{
        z-index: 99;
        position: absolute;
        top: -1.1rem;
    }
    .house_top{
        position: absolute;
        width: 8.2rem;
    }
    .house_box{
        background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix15.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
        background-position: top center;
        padding: 0.4rem 0.2rem 0rem 0.14rem;
        width: 8.2rem;
        .about_box_content{
            margin-top: 0.7rem;
            min-height: 3.2rem;
            padding: 0.16rem 0.13rem;
            font-size: 0.28rem;
            line-height: 0.42rem;
            background: #FFFFFF;
            border-radius: 0.2rem;
            color: #333333;
            img {
                float: right;
                margin-right: 0.1rem;
                width: 2.12rem;
                height: 2.83rem;
                border-radius: 0.2rem;
            }
        }
    }
}

.strict_box {
    margin-top: 0.32rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .strict_content {
        background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix15.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        width: 8.2rem;

        .strict_list {
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .str_img {
                width: 2.1rem;
                height: 2.1rem;
                border-radius: 0.21rem;
            }

            .str_name {
                width: 100%;
                font-size: 0.18rem;
                color: #FFFFFF;
                font-weight: 700;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                padding-top: 0.16rem;
            }

            .str_price {
                padding: 0.08rem 0.32rem;
                background: #FF8A00;
                color:#0D131A;
                width: fit-content;
                font-size: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.6rem;
                font-weight: 700;
                margin-top: 0.2rem;
            }
        }
    }
}

.hot_box {
    display: flex;
    justify-content: center;
    margin-top: 0.32rem;
    .hot_content{
        width: 8.21rem;
        box-sizing: border-box;
        .hot_list {
            padding: 0.7rem 0.24rem;
            width: 8.21rem;
            height: 4.8rem;
            background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix10.png');
            background-repeat: no-repeat;
            background-size: 100%;
            .hot_item{
                display: flex;
                .l {
                    width: 3.2rem;
                    height: 3.2rem;
                    background: #FFFFFF;
                    border-radius: 0.2rem;
                    overflow: hidden;
                    .l_img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .r {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 0.1rem;

                    .r_name {
                        line-height: 0.48rem;
                        font-weight: bold;
                        font-size: 0.32rem;
                        color: #fff;
                    }

                    .r_price {
                        width: fit-content;
                        padding: 0.08rem 0.32rem;
                        background: #FF8A00;
                        color:#0D131A;
                        font-size: 0.4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 0.6rem;
                        font-weight: 700;
                    }

                }
            }
        }
    }
}
.engineer_box{
    display: flex;
    justify-content: center;
    .engineer_list {
        width: 8.21rem;
        height: 4rem;
        padding: 0.4rem;
        margin-top: 0.24rem;
        background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix13.png');
        background-repeat: no-repeat;
        background-size: 100%;
        .en_top{
            display: flex;
            margin-bottom: 0.22rem;
            align-items: center;
            .engineer_title {
                font-weight: 700;
                font-size: 0.28rem;
                color: #FFFFFF;
            }
            .engineer_tip{
                padding: 0.07rem 0.1rem;
                font-size: 0.24rem;
                color: #3486F4;
                line-height: 0.36rem;
                background: #E5ECFC;
                margin-left: 0.18rem;
                border-radius: 0.08rem;
            }
        }
        
        .engi_info {
            .l_block:nth-child(1),.l_block:nth-child(2),.l_block:nth-child(3){
                margin-bottom: 0.24rem;
            }
            .l_block {
                font-size: 0.24rem;
                
                display: flex;
                color: #FFFFFF;
                .block_txt {
                    flex:1 ;
                }
            }
            .engi_info_top{
                display: flex;
                .r {
                    flex: 1;
                }
                .l {
                    width: 2.16rem;
                    height: 2.16rem; 
                    margin-right: 0.16rem;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}


.zizhi_box {
    margin-top: 0.32rem;
    padding-bottom: 0.32rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .zizhi_content {
        width: 8.2rem;
        background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/cImg/TplSix15.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: top center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .zizhi_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 0.3rem;

            img {
                width: 3.39rem;
                height: 3.39rem;
                background: #FFFFFF;
                border-radius: 0.16rem
            }
        }
    }
}

</style>