<template>
    <!-- 模板二 -->
    <div class="tpl_two">
        <div class="base-width">
            <!-- 面包屑 -->
            <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
            <!-- 店铺 -->
            <ShopBase :shop_info="shop_info" @followClick="followClick" @searchClick="searchClick"></ShopBase>
            <!-- 切换栏 -->
            <div class="tabs_box" v-ani.fade-up>
                <div :class="['tabs', tabsIndex == index ? 'active' : '']" v-for="(item, index) in tabsArr" :key="index"
                    @click="() => tabsIndex = index">
                    {{ item }}
                </div>
            </div>
            <!-- 首页 -->
            <template v-if="tabsIndex == 0">
                <Banner style="margin-top: 0.2rem;" class="img" :list="tplObj.top_banner" height="6rem" v-ani.fade-up />
                <div class="home_box">
                    <!-- 公司简介 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg83.png">
                    </div>
                    <div class="about_box" v-ani.fade-up>
                        <div class="about_bottom">
                            <div class="bott_block">
                                <div class="b_num">{{ tplObj.company_schaft_time }}</div>
                            </div>
                            <div class="bott_block"
                                style="background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg90.png');">
                                <div class="b_num">{{ tplObj.registerkapital }}</div>
                            </div>
                            <div class="bott_block"
                                style="background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg91.png');">
                                <div class="b_num">{{ tplObj.unternehmen }}</div>
                            </div>
                        </div>
                        <div class="about_content">
                            <img :src="tplObj.company_profile_image">
                            {{ tplObj.company_profile }}
                        </div>
                    </div>
                    <!-- 源头好货 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg84.png">
                    </div>
                    <div class="source_box">
                        <div class="source_content" v-ani.fade-up>
                            <img :src="tplObj.good_source_image">
                            {{ tplObj.good_source }}
                        </div>
                        <div class="zizhi_title">店铺资质证明</div>
                        <div class="zizhi_sub_title">资质权威，值得托付</div>
                        <div class="zizhi_content" v-ani.fade-up>
                            <viewer :images="tplObj.certificate_image">
                                <img v-for="(item, index) in tplObj.certificate_image" :key="index" :src="item"
                                    v-ani.fade-up>
                            </viewer>
                        </div>
                    </div>
                    <!-- 严选好物 -->
                    <div class="center_img">
                        <img class="img_two" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg85.png">
                    </div>
                    <div class="strict_box">
                        <div class="strict_list" v-for="(item, index) in tplObj.select_good" :key="index" v-ani.fade-up
                            @click="jumpClick(item.source_id)">
                            <img class="str_img" :src="item.image" />
                            <div class="str_name">{{ item.goods_name }}
                            </div>
                            <div class="str_price">￥{{ item.price }} / {{ item.unit_name }}</div>
                        </div>
                    </div>
                    <!-- 店铺热销 -->
                    <div class="center_img">
                        <img class="img_two" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg86.png">
                    </div>
                    <div class="hot_list" v-for="(item, index) in tplObj.hot_store" :key="item.jump_goods_id" v-ani.fade-up
                        @click="jumpClick(item.source_id)">
                        <div class="l">
                            <img class="l_img" :src="item.image" />
                            <img class="l_label" v-if="index == 0"
                                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon491.png" />
                            <img class="l_label" v-if="index == 1"
                                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon492.png" />
                            <img class="l_label" v-if="index == 2"
                                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon493.png" />
                        </div>
                        <div class="r">
                            <div class="r_name">{{ item.goods_name }}</div>
                            <div class="r_price">
                                <div class="price_line">
                                    促销价 <span>￥{{ item.price }}</span>
                                </div>
                                <img src="../../../assets/images/tplImg/img3.png" />
                            </div>
                        </div>
                    </div>
                    <!-- 供应项目 -->
                    <div class="center_img">
                        <img class="img_one" src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg87.png">
                    </div>
                    <div class="supply_list" v-for="(item, index) in tplObj.supply_project" :key="index" v-ani.fade-up>
                        <div class="supp_title">{{ item.title }}</div>
                        <div class="supply_content">
                            <div class="l">

                                <div class="supp_two">项目规模：<span>{{ item.project_scale || '暂无' }}</span>
                                </div>
                                <div class="supp_two">业主单位：<span>{{ item.owner_unit || '暂无' }}</span>
                                </div>
                                <div class="supp_two">
                                    总承包单位：<span>{{ item.total_unit || '暂无' }}</span></div>
                                <div class="supp_two">
                                    项目投资规模：<span>{{ item.authentication_money || '暂无' }}</span></div>
                                <div class="supp_two">项目位置：<span>{{ item.project_location || '暂无' }}</span>
                                </div>
                                <div class="supp_btn" @click="jumpClick(item.source_id)">
                                    <img src="../../../assets/images/tplImg/img4.png">
                                </div>
                            </div>
                            <div class="r">
                                <ImgRatio :src='item.original_img' :width="400" :height="300" class="img" />
                            </div>
                        </div>

                    </div>
                </div>

            </template>
            <!-- 商品 -->
            <div style="background-color: #ecf5fa;padding-top: 20px;margin-top: 20px;" v-if="tabsIndex == 1">
                <div class="base-width">
                    <!-- 筛选 -->
                    <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
                    <div class="md-prod-list">
                        <ProductItem v-for="item in goodsArr" :value='item' :key="item.goods_id" v-ani.fade-up />
                    </div>
                    <!-- 分页 -->
                    <div style="margin-top: 0.2rem;"></div>
                    <Pagination v-show="goodsTotal > 0 && tabsIndex == 1" :page="goodsData.p" :size="goodsData.page_num"
                        :total="goodsTotal" v-ani.fade-up @change="changePage" :key="pagKey" />
                    <div style="padding-bottom: 0.6rem;" v-if="tabsIndex == 1"></div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routes: { type: Array, default: () => [] },
        shop_info: { type: Object, default: () => { } },
        goodsArr: { type: Array, default: () => [] },
        goodsTotal: { type: Number, default: 0 },
        pagKey: { type: Number, default: 0 },
        goodsData: { type: Object, default: () => { } },
        tplObj: { type: Object, default: () => { } },

    },
    created() {

    },
    data() {
        return {
            tabsArr: ['首页', '商品'],
            tabsIndex: 0,
        }
    },
    methods: {
        jumpClick(url) {
            window.open(url, '_blank');
        },
        followClick() {
            this.$emit('followFn');
        },
        searchClick(keywords) {
            this.$emit('searchFn', keywords);
            this.tabsIndex = 1;
        },
        filterFn(obj) {
            this.$emit('filterFn', obj)
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
<style lang="scss" scoped>
.tabs_box {
    margin-top: 0.2rem;
    display: flex;

    .tabs {
        margin-right: 0.2rem;
        width: 0.7rem;
        height: 0.4rem;
        line-height: 0.4rem;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-size: 0.2rem;
        cursor: pointer;

        &.active {
            background: linear-gradient(90deg, #6FB1FF 0%, #0261D5 100%);
            color: #fff;

        }
    }
}

.md-prod-list {
    margin-top: 0.2rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.1rem;
}

.center_img {
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .img_one {
        width: 4.58rem;
        height: 0.74rem;
        z-index: 10;
    }

    .img_two {
        width: 4.84rem;
        height: 0.9rem;
    }
}

.home_box {
    width: 100%;
    padding: 0 1.5rem 0.5rem;
    background-color: #DC3432;
}

.about_box {
    padding-bottom: 30px;
    margin-bottom: -0.45rem;
    transform: translateY(-0.45rem);
    background: linear-gradient(180deg, #FDF4E4 0%, #E2AB45 100%);
    border-radius: 10px;
    border: 1px solid #C88B5B;

    .about_bottom {
        margin-top: 0.36rem;
        display: flex;
        justify-content: space-around;

        .bott_block {
            width: 2.49rem;
            height: 1.46rem;
            background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg89.png');
            background-size: 100%;

            .b_num {
                margin-top: 0.45rem;
                font-weight: bold;
                font-size: 0.26rem;
                color: #AC2E2A;
                text-align: center;
            }
        }
    }

    .about_content {
        margin: 0.28rem;
        min-height: 3.5rem;
        padding: 0.3rem;
        box-sizing: border-box;
        font-size: 0.2rem;
        color: #FCE8B5;
        line-height: 0.3rem;
        background-color: #DC3432;
        border-radius: 0.2rem;

        img {
            float: right;
            margin-left: 0.5rem;
            width: 2rem;
            height: 2.67rem;
        }
    }
}

.source_box {
    padding: 0.24rem;
    background: linear-gradient(180deg, #FCF3E3 0%, #E3AB45 100%);
    border-radius: 0.2rem;
    border: 1px solid #C88B5B;

    .source_content {
        min-height: 2.2rem;
        font-size: 0.18rem;
        color: #AC2E2A;
        border-radius: 0.2rem;

        img {
            margin-right: 0.2rem;
            margin-bottom: 0.2rem;
            float: left;
            width: 2.8rem;
            height: 2.1rem;
        }
    }

    .zizhi_title {
        margin-top: 0.2rem;
        text-align: center;
        font-weight: bold;
        font-size: 0.36rem;
        color: #AC2E2A;
    }

    .zizhi_sub_title {
        margin-top: 0.2rem;
        text-align: center;
        font-size: 0.2rem;
        color: #AC2E2A;
    }

    .zizhi_content {
        display: flex;
        flex-wrap: wrap;

        img {
            margin-top: 0.2rem;
            margin-right: 0.2rem;
            width: 1.95rem;
            height: 1.57rem;
            border-radius: 0.04rem;
        }
    }
}

.strict_box {
    padding: 0.4rem;
    background: linear-gradient(180deg, #FDF4E4 0%, #E2AB45 100%);
    border-radius: 0.1rem;
    border: 1px solid #C88B5B;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.2rem;

    .strict_list {
        background-color: #DC3432;
        border-radius: 0.1rem;

        .str_img {
            width: 100%;
            display: block;
            height: 4rem;
            border-radius: 0.1rem 0.1rem 0 0;
        }

        .str_name {
            width: 100%;
            height: 0.88rem;
            padding: 0.1rem;
            box-sizing: border-box;
            font-size: 0.32rem;
            color: #FCE8B5;
            font-weight: 700;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 超出几行省略 */
            overflow: hidden;
        }

        .str_price {
            padding: 0.1rem;
            font-size: 0.28rem;
            color: #FCE8B5;
            text-align: right;
        }
    }
}

.hot_list {
    margin-top: 0.2rem;
    padding: 0.24rem;
    margin-top: 20rpx;
    background: linear-gradient(180deg, #FCF3E3 0%, #E3AB45 100%);
    border-radius: 0.1rem;
    border: 1px solid #C88B5B;
    display: flex;

    .l {
        width: 2.8rem;
        height: 2.1rem;
        background: #FFFFFF;
        border-radius: 0.1rem;
        overflow: hidden;
        position: relative;

        .l_label {
            width: 0.49rem;
            height: 0.69rem;
            position: absolute;
            top: 0rem;
            left: 0.4rem;
        }

        .l_img {
            width: 100%;
            height: 100%;
        }
    }

    .r {
        flex: 1;
        margin-left: 0.1rem;

        .r_name {
            height: 1.5rem;
            font-weight: bold;
            font-size: 0.3rem;
            color: #333333;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        .r_price {
            display: flex;
            align-items: center;

            .price_line {
                padding: 0 0.2rem;
                height: 0.6rem;
                line-height: 0.6rem;
                width: fit-content;
                background-color: #DC3432;
                color: #FCE8B5;
                font-size: 0.18rem;

                span {
                    font-size: 0.48rem;
                    font-weight: 700;
                }
            }

            img {
                width: 0.3rem;
                height: 0.6rem;
            }
        }

    }
}

.supply_list {
    margin-top: 0.2rem;
    padding: 0.4rem;
    box-sizing: border-box;
    width: 100%;
    background-color: #fdf5e7;
    border-radius: 10px;


    .supp_title {
        font-weight: bold;
        font-size: 0.3rem;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: #ac2e2a;
    }

    .supply_content {
        display: flex;
        justify-content: space-between;

        .l {
            flex: 1;

            .supp_two {
                margin-top: 0.2rem;
                font-size: 0.2rem;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                color: #666666;

                span {
                    color: #AC2E2A;
                }
            }

            .supp_btn {
                margin-top: 0.5rem;

                img {
                    width: 1.56rem;
                    height: 0.54rem;
                }
            }
        }

        .r {
            margin-left: 0.3rem;
            width: 4rem;
            height: 3rem;
        }
    }


}
</style>