<template>
  <!-- 店铺页 -->
  <Layout>
    <template v-if="judObj.use_old_tpl">
      <div class="main-seller">
        <div class="base-width">
          <!-- 面包屑 -->
          <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
          <!-- 搜索 -->
          <Search v-ani.fade-up @change="changeKeyword" placeholder="请输入关键商品信息" />
          <div class="md-seller-1">
            <div class="md-seller-1__lf">
              <!-- 店铺信息 -->
              <ProductSeller :shop_info="infoObj.shop_info" @followFn="followFn" v-ani.fade-up />
              <!-- banner -->
              <Banner class="img" :list="infoObj.banner" height="4.85rem" v-ani.fade-up />
            </div>
            <!-- 店铺数据 -->
            <ProductData :shop_mouth="infoObj.shop_mouth" :goods_experience="infoObj.goods_experience"
              :business_performance="infoObj.business_performance" :buyer_key="infoObj.buyer_key" class="md-seller-1__rg"
              v-ani.fade-left />
          </div>
          <!-- 筛选 -->
          <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
          <!-- 产品列表 -->
          <div class="md-prod-list">
            <template v-for="item in goodsArr">
              <ProductItem :value='item' :key="item.goods_id" v-ani.fade-up />
            </template>
          </div>
          <!-- 分页 -->
          <Pagination v-show="goodsTotal > 0" :page="goodsData.p" :size="goodsData.page_num" :total="goodsTotal"
            v-ani.fade-up @change="changePage" :key="pagKey" />
        </div>
      </div>
    </template>
    <!-- 模板一 -->
    <TplOne v-if="!judObj.use_old_tpl && judObj.tpl_id == 1" :routes="routes" :shop_info="infoObj.shop_info"
      :goodsArr="goodsArr" :goodsTotal="goodsTotal" :goodsData="goodsData" :pagKey="pagKey" :tplObj="tplObj"
      @followFn="followFn" @changePage="changePage" @searchFn="searchFn" @filterFn="filterFn" />
    <!-- 模板二 -->
    <TplTwo v-if="!judObj.use_old_tpl && judObj.tpl_id == 2" :routes="routes" :shop_info="infoObj.shop_info"
      :goodsArr="goodsArr" :goodsTotal="goodsTotal" :goodsData="goodsData" :pagKey="pagKey" :tplObj="tplObj"
      @followFn="followFn" @changePage="changePage" @searchFn="searchFn" @filterFn="filterFn" />
    <!-- 模板三 -->
    <TplThree v-if="!judObj.use_old_tpl && judObj.tpl_id == 3" :routes="routes" :shop_info="infoObj.shop_info"
      :goodsArr="goodsArr" :goodsTotal="goodsTotal" :goodsData="goodsData" :pagKey="pagKey" :tplObj="tplObj"
      @followFn="followFn" @changePage="changePage" @searchFn="searchFn" @filterFn="filterFn" />
    <!-- 模板四 --> 
    <TplFour v-if="!judObj.use_old_tpl && judObj.tpl_id == 4" :routes="routes" :shop_info="infoObj.shop_info"
      :goodsArr="goodsArr" :goodsTotal="goodsTotal" :goodsData="goodsData" :pagKey="pagKey" :tplObj="tplObj"
      @followFn="followFn" @changePage="changePage" @searchFn="searchFn" @filterFn="filterFn" />
    <!-- 模板五 --> 
    <TplFive v-if="!judObj.use_old_tpl && judObj.tpl_id == 5" :routes="routes" :shop_info="infoObj.shop_info"
      :goodsArr="goodsArr" :goodsTotal="goodsTotal" :goodsData="goodsData" :pagKey="pagKey" :tplObj="tplObj"
      @followFn="followFn" @changePage="changePage" @searchFn="searchFn" @filterFn="filterFn" />
    <!-- 模板六 --> 
    <TplSix v-if="!judObj.use_old_tpl && judObj.tpl_id == 6" :routes="routes" :shop_info="infoObj.shop_info"
      :goodsArr="goodsArr" :goodsTotal="goodsTotal" :goodsData="goodsData" :pagKey="pagKey" :tplObj="tplObj"
      @followFn="followFn" @changePage="changePage" @searchFn="searchFn" @filterFn="filterFn" />
  </Layout>
</template>

<script>
import { IndexshopInfoApi, GoodsListApi, CollectSuppliersApi, ShopTplJudTplApi, ShopTplPreviewApi } from "@/request/api/shop";
import TplOne from "./tpl/TplOne";
import TplTwo from "./tpl/TplTwo.vue";
import TplThree from "./tpl/TplThree.vue";
import TplFour from "./tpl/TplFour.vue";
import TplFive from "./tpl/TplFive.vue";
import TplSix from "./tpl/TplSix.vue";
export default {
  components: { TplOne, TplTwo, TplThree, TplFour, TplFive, TplSix },
  created() {
    this.suppliers_id = this.$route.query.suppliers_id;
    this.goodsData.suppliers_id = this.$route.query.suppliers_id;
    this.detailFn();
    this.goodsFn();
    ShopTplJudTplApi({ suppliers_id: this.suppliers_id }).then(({ status, data }) => {
      if (status == 200) {
        this.judObj = data;
        if (!data.use_old_tpl) {
          ShopTplPreviewApi({ tpl_id: data.tpl_id, status: 'check', suppliers_id: this.suppliers_id }).then((res) => {
            if (res.status == 200) {
              res.data.top_banner.map(item => {
                item.id = item.jump_goods_id;
                item.ad_code = item.image;
              })
              this.tplObj = res.data;

            }
          })
        }
      }
    })
  },
  data() {
    return {
      judObj: {
        use_old_tpl: false,  // 是否是默认模版
        tpl_id: "",
      },
      tplObj: {
        top_banner: [],
        select_good: [],
        hot_store: [],
        certificate_image: [],
      },
      infoObj: {
        banner: [],
        shop_info: { level: {} },
        shop_mouth: {},
        goods_experience: {},
        business_performance: {},
        buyer_key: [],
      },
      goodsData: {
        p: 1,
        page_num: 15,
        suppliers_id: "",
        keyword: "",
        order: 1,
        sort: "",
        min_price: "",
        max_price: ""
      },
      goodsArr: [],
      goodsTotal: 0,
      routes: [
        { name: '首页', link: '/' },
        { name: '集采商城', link: '/shop' },
        { name: '店铺详情页', link: '' }
      ],
      pagKey: 0,
    };
  },
  methods: {
    searchFn(val) {
      this.goodsData.keyword = val;
      this.goodsData.p = 1;
      this.goodsFn();
    },
    // 关注
    followFn() {
      CollectSuppliersApi({ suppliers_id: this.infoObj.shop_info.suppliers_id }).then(({ status }) => {
        if (status == 200) {
          this.$message.success('操作成功');
          this.detailFn();
        }
      })
    },
    // 详情
    detailFn() {
      IndexshopInfoApi({ suppliers_id: this.suppliers_id }).then(({ status, data }) => {
        if (status == 200) {
          this.infoObj = data;
        }
      })
    },
    // 参数筛选
    filterFn(obj) {
      this.goodsData.p = 1;
      this.goodsData.order = obj.order;
      this.goodsData.sort = obj.sort;
      this.goodsData.min_price = obj.min_price;
      this.goodsData.max_price = obj.max_price;
      this.goodsFn();
    },
    // 商品列表
    goodsFn() {
      GoodsListApi(this.goodsData).then(({ status, data }) => {
        if (status == 200) {
          this.goodsArr = data.list;
          this.goodsTotal = data.total;
          this.pagKey++;
        }
      })
    },
    // 分页
    changePage(page) {
      this.goodsData.p = page;
      this.goodsFn();
    },
    changeKeyword(val) {
      this.goodsData.keyword = val;
      this.goodsFn();
    },

  }
};
</script>

<style lang="scss" scoped>
.main-seller {
  padding-bottom: .6rem;

  .c-breadcrumbs {
    margin: .18rem 0;
  }

  .md-pagination {
    margin-top: .4rem;
  }

  .md-prod-list {
    margin-top: .2rem;
    display: flex;
    flex-wrap: wrap;

    .c-product-item {
      width: 3.04rem;
      margin-bottom: .2rem;
      margin-right: .2rem;

      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
  }
}

.banner {
  margin-top: .19rem;
}

::v-deep .banner .text {
  top: 45.7%;

  .tit {
    font-size: .38rem;
    line-height: 1.5;
  }
}

.md-seller-1 {
  display: flex;

  &__lf {
    flex: 1;
    overflow: hidden;
  }

  &__rg {
    width: 4.52rem;
    margin-left: .4rem;
  }
}
</style>