<template>
    <!-- 模板一 -->
    <div class="tpl_one">
        <div class="base-width">
            <!-- 面包屑 -->
            <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
            <!-- 店铺 -->
            <ShopBase :shop_info="shop_info" @followClick="followClick" @searchClick="searchClick"></ShopBase>
            <!-- 切换栏 -->
            <div class="tabs_box" v-ani.fade-up>
                <div :class="['tabs', tabsIndex == index ? 'active' : '']" v-for="(item, index) in tabsArr" :key="index"
                    @click="() => tabsIndex = index">
                    {{ item }}
                </div>
            </div>
        </div>
        <!-- 首页 -->
        <template v-if="tabsIndex == 0">
            <Banner style="margin-top: 0.2rem;" class="img" :list="tplObj.top_banner" height="6rem" v-ani.fade-up />
            <div class="about_box base-width" v-ani.fade-up>
                <div class="ab_title">About__</div>
                <div class="ab_sub_title">公司简介</div>
                <div class="company_box">
                    <div class="l">
                        <div class="com_txt">{{ tplObj.company_profile }}</div>
                        <div class="com_info">
                            <div class="in_block">
                                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg75.png">
                                <div class="num">{{ tplObj.company_schaft_time }}</div>
                                <div class="txt">公司成立</div>
                            </div>
                            <div class="in_block">
                                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg76.png">
                                <div class="num">{{ tplObj.registerkapital }}</div>
                                <div class="txt">注册资金</div>
                            </div>
                            <div class="in_block">
                                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg77.png">
                                <div class="num">{{ tplObj.unternehmen }}</div>
                                <div class="txt">公司规模</div>
                            </div>
                        </div>
                    </div>
                    <div class="r">
                        <img :src="tplObj.company_profile_image">
                    </div>
                </div>
            </div>
            <div class="source_box" v-ani.fade-up>
                <div class="base-width">
                    <div class="bg_title">源头好货</div>
                    <div class="bg_sub_title">源头直供，好货不愁！品质卓越，品质无忧</div>
                    <div class="source_info">
                        <div class="l">
                            <div class="source_txt">{{ tplObj.good_source }} </div>
                            <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg79.png">
                        </div>
                        <div class="r">
                            <img :src="tplObj.good_source_image">
                        </div>
                    </div>
                    <div class="bottom_img" v-ani.fade-up>
                        <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg80.png">
                    </div>
                </div>
            </div>
            <div class="strict_box">
                <div class="bg_title">严选好物</div>
                <div class="bg_sub_title">严选为王，品质商品，严谨把关，选顶级之品</div>
                <div class="goods_box">
                    <div class="goods_list" v-for="(item, index) in tplObj.select_good" :key="index"
                        @click="jumpClick(item.source_id)" v-ani.fade-up>
                        <div class="goods_img">
                            <img :src='item.image'>
                        </div>
                        <div class="goods_name">
                            <!-- <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon481.png"> -->
                            {{ item.goods_name }}
                        </div>
                        <div class="goods_price">
                            ￥{{ item.price }}/{{ item.unit_name }}
                            <!-- <span>5件起批 </span> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="base-width" v-ani.fade-up>
                <div class="bg_title">店铺热销</div>
                <div class="bg_sub_title">源头直供好物，品质保障无忧！</div>
                <div class="hot_box">
                    <div class="hot_list" v-for="(item, index) in tplObj.hot_store" :key="index"
                        @click="jumpClick(item.source_id)" v-ani.fade-up>
                        <div class="hot_img">
                            <img class="bg_img" :src="item.image">
                            <img class="label_sort" v-if="index == 0"
                                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon485.png">
                            <img class="label_sort" v-if="index == 1"
                                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon486.png">
                            <img class="label_sort" v-if="index == 2"
                                src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon487.png">
                        </div>
                        <div class="hot_name">{{ item.goods_name }}</div>
                        <div class="hot_btn">
                            <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon484.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="supply_box" v-if="tplObj.supply_project">
                <div class="base-width" v-ani.fade-up>
                    <div class="bg_title">供应项目</div>
                    <div class="bg_sub_title">联手官方共赢，项目真实可溯源！</div>
                    <div class="supply_list" v-for="(item, index) in tplObj.supply_project" :key="index" v-ani.fade-up>
                        <div class="l">
                            <div class="supp_one">{{ item.title }}</div>
                            <div class="supp_two">项目规模：<span>{{ item.project_scale || '暂无' }}</span>
                            </div>
                            <div class="supp_two">业主单位：<span>{{ item.owner_unit || '暂无' }}</span>
                            </div>
                            <div class="supp_two">
                                总承包单位：<span>{{ item.total_unit || '暂无' }}</span></div>
                            <div class="supp_two">
                                项目投资规模：<span>{{ item.authentication_money || '暂无' }}</span></div>
                            <div class="supp_two">项目位置：<span>{{ item.project_location || '暂无' }}</span>
                            </div>
                            <div class="supp_btn" @click="jumpClick(item.source_id)">
                                <img src="https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/icon488.png">
                            </div>
                        </div>
                        <div class="r">
                            <ImgRatio :src='item.original_img' :width="400" :height="300" class="img" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 资质证明 -->
            <div class="aptitude_box">
                <div class="apt_t_img"></div>
                <div class="apt_b">
                    <div class="base-width apt_content">
                        <div class="l">
                            <div class="l_ti">店铺资质证明</div>
                            <div class="l_sub_ti">资质权威，值得托付</div>
                        </div>
                        <div class="r">
                            <div class="apti_img">
                                <viewer :images="tplObj.certificate_image">
                                    <img v-for="(item, index) in tplObj.certificate_image" :src="item" v-ani.fade-up>
                                </viewer>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </template>
        <!-- 商品 -->
        <div style="background-color: #ecf5fa;padding-top: 20px;margin-top: 20px;" v-if="tabsIndex == 1">
            <div class="base-width">
                <!-- 筛选 -->
                <ProductFilter :totalNum="goodsTotal" @change="filterFn" :official="false" v-ani.fade-up />
                <div class="md-prod-list">
                    <ProductItem v-for="item in goodsArr" :value='item' :key="item.goods_id" v-ani.fade-up />
                </div>
                <!-- 分页 -->
                <Pagination v-show="goodsTotal > 0 && tabsIndex == 1" :page="goodsData.p" :size="goodsData.page_num"
                    :total="goodsTotal" v-ani.fade-up @change="changePage" :key="pagKey" />
                <div style="padding-bottom: 0.6rem;" v-if="tabsIndex == 1"></div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routes: { type: Array, default: () => [] },
        shop_info: { type: Object, default: () => { } },
        goodsArr: { type: Array, default: () => [] },
        goodsTotal: { type: Number, default: 0 },
        pagKey: { type: Number, default: 0 },
        goodsData: { type: Object, default: () => { } },
        tplObj: { type: Object, default: () => { } },

    },
    created() {

    },
    data() {
        return {
            tabsArr: ['首页', '商品'],
            tabsIndex: 0,
        }
    },
    methods: {
        filterFn(obj) {
            this.$emit('filterFn', obj)
        },
        searchClick(keywords) {
            this.$emit('searchFn', keywords);
            this.tabsIndex = 1;
        },
        jumpClick(url) {
            window.open(url, '_blank');
        },
        followClick() {
            this.$emit('followFn');
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
<style lang="scss" scoped>
.tpl_one {
    // padding-bottom: 0.2rem;
    background-color: #fff;
}

.bg_title {
    line-height: 1.2rem;
    text-align: center;
    font-weight: bold;
    font-size: 0.36rem;
    color: #0261D5;
}

.bg_sub_title {
    text-align: center;
    font-weight: 400;
    font-size: 0.2rem;
}


.tabs_box {
    margin-top: 0.2rem;
    display: flex;

    .tabs {
        margin-right: 0.2rem;
        width: 0.7rem;
        height: 0.4rem;
        line-height: 0.4rem;
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        font-size: 0.2rem;
        cursor: pointer;

        &.active {
            background: linear-gradient(90deg, #6FB1FF 0%, #0261D5 100%);
            color: #fff;

        }
    }
}

.md-prod-list {
    margin-top: 0.2rem;
    display: flex;
    flex-wrap: wrap;

    .c-product-item {
        width: 3.04rem;
        margin-bottom: .2rem;
        margin-right: .2rem;

        &:nth-of-type(5n) {
            margin-right: 0;
        }
    }
}

.about_box {
    padding-top: 1.5rem;
    padding-bottom: 2rem;

    .ab_title {
        font-size: 0.36rem;
        font-weight: 700;
    }

    .ab_sub_title {
        margin-top: 0.1rem;
        font-weight: 700;
        font-size: 0.2rem;
    }

    .company_box {
        display: flex;

        .l {
            flex: 1;
            margin-right: 0.8rem;

            .com_txt {
                line-height: 0.3rem;
                margin-top: 0.28rem;
                font-size: 0.2rem;
            }

            .com_info {
                margin-top: 1rem;
                display: flex;

                .in_block {
                    margin-right: 0.15rem;
                    width: 2rem;
                    height: 1.48rem;
                    background: #F2F5F8;
                    border-radius: 4px 4px 4px 4px;
                    position: relative;

                    img {
                        width: 1.52rem;
                        height: 1.12rem;
                        position: absolute;
                        left: 0;
                        top: 0.2rem;
                    }

                    .num {
                        margin-top: 0.3rem;
                        margin-left: 1rem;
                        font-weight: 500;
                        font-size: 0.22rem;
                        color: #0261D5;
                    }

                    .txt {
                        margin-left: 1rem;
                        margin-top: 0.1rem;
                        font-weight: 500;
                        font-size: 0.16rem;

                    }
                }
            }
        }

        .r {
            width: 3.4rem;
            height: 4.3rem;

            img {
                width: 100%;
                height: 100%;
            }


        }
    }
}

.source_box {
    width: 100%;
    height: 12.2rem;
    background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg78.png');
    background-size: 100%;

    .source_info {
        margin-top: 0.7rem;
        display: flex;

        .l {
            margin-right: 0.2rem;
            flex: 1;
            position: relative;

            .source_txt {
                line-height: 0.3rem;
                font-size: 0.2rem;
            }

            img {
                width: 100%;
                height: 2.1rem;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        .r {
            width: 8rem;
            height: 6rem;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .bottom_img {
        margin-top: 0.2rem;
        width: 100%;
        height: 3.1rem;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.strict_box {
    padding-bottom: 1.4rem;
    width: 100%;
    // height: 15.2rem;
    background-image: url('https://yizhu-new.oss-cn-shenzhen.aliyuncs.com/app_img/aIcon/amg81.png');
    background-size: 100%;

    .goods_box {
        margin-top: 0.2rem;
        width: 10rem;
        max-width: 98%;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.2rem;

        .goods_list {
            padding-bottom: 0.1rem;
            box-shadow: 0px 3px 6px 1px rgba(136, 169, 203, 0.16);
            border-radius: 4px 4px 4px 4px;

            .goods_img {
                width: 100%;
                height: 3.5rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .goods_name {
                margin: 0.1rem 0;
                padding: 0 0.1rem;
                box-sizing: border-box;
                height: 0.5rem;
                width: 100%;
                font-size: 0.2rem;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: normal; //处理元素中的 空白
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -moz-box;
                -moz-line-clamp: 2;
                -moz-box-orient: vertical;
                overflow-wrap: break-word;
                word-break: break-all;

                img {
                    width: 0.96rem;
                    height: 0.2rem;
                }
            }

            .goods_price {
                padding: 0 0.1rem;
                font-size: 0.2rem;
                color: #EA3323;

                span {
                    font-size: 0.15rem;
                    color: #999999;
                }
            }
        }
    }
}

.hot_box {
    padding-bottom: 0.2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.2rem;

    .hot_list {
        margin-top: 0.2rem;
        padding-bottom: 0.2rem;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(136, 169, 203, 0.16);
        border-radius: 4px 4px 4px 4px;

        .hot_img {
            width: 100%;
            height: 4rem;
            border-radius: 4px;
            overflow: hidden;
            position: relative;

            .label_sort {
                width: 0.8rem;
                height: 0.5rem;
                position: absolute;
                top: 0;
                left: 0;
            }

            .bg_img {
                width: 100%;
                height: 100%;
            }
        }

        .hot_name {
            margin: 0.1rem 0;
            padding: 0 0.1rem;
            box-sizing: border-box;
            font-size: 0.3rem;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }

        .hot_btn {
            display: flex;
            justify-content: flex-end;

            img {
                margin-right: 0.2rem;
                width: 0.4rem;
                height: 0.4rem;
            }
        }
    }
}

.supply_box {
    padding-bottom: 0.2rem;
    width: 100%;
    background-color: #F7F7F7;

    .supply_list {
        margin-top: 0.2rem;
        padding: 0.4rem;
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;

        .l {
            flex: 1;

            .supp_one {
                font-weight: bold;
                font-size: 0.3rem;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }

            .supp_two {
                margin-top: 0.2rem;
                font-size: 0.2rem;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;

                span {
                    color: #999;
                }
            }

            .supp_btn {
                margin-top: 0.5rem;

                img {
                    width: 1.56rem;
                    height: 0.54rem;
                }
            }
        }

        .r {
            margin-left: 0.3rem;
            width: 4rem;
            height: 3rem;
        }
    }
}

.aptitude_box {
    .apt_t_img {
        width: 100%;
        min-height: 0.92rem;
        background-image: url('../../../assets/images/tplImg/img1.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: #F7F7F7;
    }

    .apt_b {
        width: 100%;
        min-height: 2.36rem;
        background-image: url('../../../assets/images/tplImg/img2.png');
        background-size: 100%;

        .apt_content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .l {
                flex-shrink: 0;

                .l_ti {
                    font-weight: bold;
                    font-size: 36px;
                    color: #FFFFFF;
                }

                .l_sub_ti {
                    margin-top: 0.2rem;
                    font-size: 20px;
                    color: #FFFFFF;
                }
            }

            .r {
                flex: 1;

                .apti_img {
                    height: 3.28rem;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-end;

                    img {
                        margin-right: 0.1rem;
                        width: 2.2rem;
                        height: 1.9rem;
                        border-radius: 4px 4px 4px 4px;
                        object-fit: cover;
                    }
                }
            }
        }
    }

}
</style>